import { createWebHistory, createRouter } from "vue-router";
import axios from 'axios';
import routes from "./routes";
import appConfig from "../../app.config";
import Swal from "sweetalert2";
import { helper } from "../helpers/helper";
import { useStore } from "../state/useStore";
import { cacheStore } from "../state/cacheStore";

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach((routeTo, routeFrom, next) => {

  const store = useStore();
  const cache = cacheStore();

  const publicPages = ["/login"];
  const authpage = !publicPages.includes(routeTo.path);
  if (localStorage.getItem('useStore') != undefined) {
    const state = JSON.parse(localStorage.getItem('useStore'))
    const loggeduser = state.user;
    if (authpage && !loggeduser) {
      return next("/login");
    } else {
      const jwt = state.jwt
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    }
  } else {
    return next("/login");
  }


  let existsIssue = false;

  if (!window.location.href.includes(routeTo.fullPath)) {
    if (cache.cache['cacheCalendario']) {
      cache.cache['cacheCalendario'] = null
    }

    const claveAEliminar = `/v2/create-filter-mantis ${routeTo.fullPath}`;
    if (cache.cache[claveAEliminar]) {
      cache.cache[claveAEliminar] = null
    }
  }

  if (!routeTo.fullPath.includes("/issues-list") && !routeTo.fullPath.includes("/issue") && !routeTo.fullPath.includes("/incidents")) {
    store.filterString = null;
  }

  if (routeTo.fullPath.includes("/events-calendar")) {
    store.currentCommandCenter = null;
    store.currentAsset = null;
    store.currentBuilding = null;
    store.currentStreetlight = null;
  }

  if (!routeTo.fullPath.includes("/last-logger-analyzers") && !routeTo.fullPath.includes("/last-logger-modems") && !routeTo.fullPath.includes("/scheduled-tasks/create-scheduled-task")) {
    store.lastLoggerCCIds = null
  }

  if (
    routeTo.fullPath.includes("/create-scheduled-issue") ||
    routeTo.fullPath.includes("/g-punto-a-punto") ||
    routeTo.fullPath.includes("/create-scheduled-task") ||
    routeTo.fullPath.includes("/gmao") ||
    routeTo.fullPath.includes("/view-calendar") ||
    routeTo.fullPath.includes("preventive-issues") ||
    routeTo.fullPath.includes("correctives-issues") ||
    routeTo.fullPath.includes("list-scheduled-issues") ||
    routeTo.fullPath.includes("chart-generator") ||
    routeTo.fullPath.includes("scheduled-issues/gantt-diagram") ||
    routeTo.fullPath.includes("/energy-management")
  ) {
    if (store.currentTownhall == null && store.currentTownhall != "null") {
      helper.swalConfirm("t-select-townall-topbar", "", "info", "js");
      if(routeFrom.fullPath.includes('list-scheduled-tasks')) {
        next("")
      } else {
        next("/");
      }
    }
  }

  if (routeFrom.fullPath.includes('/g-punto-a-punto') && routeTo.fullPath != "/login") {
    if (store.currentTownhall == null && store.currentTownhall != "null") {
      helper.swalConfirm("t-select-townall-topbar", "", "info", "js");
      next();
    }
  }

  if (!routeTo.fullPath.includes('/issue/new-issue')) {
    // localStorage.removeItem('address')
    localStorage.removeItem('address')
  }

  if (routeTo.fullPath.includes('/incidents')) {
    if (localStorage.getItem("currentStep") != undefined) {
      localStorage.removeItem('currentStep')
    }
    if (localStorage.getItem("actionForIncidents") != undefined) {
      localStorage.removeItem('actionForIncidents')
    }
    store.currentCommandCenter = null
    store.currentAsset = null
    store.currentBuilding = null
    store.currentStreetlight = null
  }

  if (routeTo.fullPath.includes("/assets/list-assets")) {
    store.currentProject = null
  }

  if (!routeTo.fullPath.includes("/g-punto-a-punto")) {
    store.activeTab = null
    if (
      useStore().performIssue != undefined &&
      useStore().performIssue != null &&
      !routeTo.fullPath.includes("/create-scheduled-task")
    ) {
      existsIssue = true;
    } else {
      if (
        routeTo.fullPath.includes("/correctives-issues") ||
        routeTo.fullPath.includes("/preventive-issues") ||
        routeTo.fullPath.includes("/gmao/issues-list/") ||
        routeTo.fullPath.includes("logger-modems/list-logger") ||
        routeTo.fullPath.includes("logger-analyzers/list-logger")
      ) {
        store.currentCommandCenter = null;
        store.currentStreetlight = null
        store.currentBuilding = null;
        store.currentAsset = null;
      }
    }
  }

  let vistasAppEdit = useStore().layoutDashboardEdit
  let editando = useStore().editando

  if (vistasAppEdit && editando) {
    Swal.fire({
      title: "No has guardado los cambios, deseas irte?",
      showDenyButton: true,
      confirmButtonText: "Si",
      confirmButtonColor: "#d93a34",
      denyButtonText: "No",
      denyButtonColor: "#9ac3e6",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        useStore().editando = false
        useStore().layoutDashboardEdit = null
        next(routeTo);
      } else if (result.isDenied) {
        useStore().editando = false
        setTimeout(() => {
          useStore().editando = true
        }, 200);

        next(routeFrom);
      }
    });
  } else if (existsIssue) {
    axios.get(`${process.env[useStore().env]}/v2/user-gmao-issue?user_gmao_id=${JSON.parse(useStore().user).gmao_user_id}`)
    .then((res) => {
      if (res.data != undefined && res.data.data != undefined && res.data.data.length > 0) {
          Swal.fire({
              title: helper.loadLocaleMessages("t-warning"),
              text: helper.loadLocaleMessages("t-cant-go-if-performing"),
              icon: 'warning'
          });

          store.performIssue = res.data.data[0].issue;
          const project = JSON.parse(res.data.data[0].project);
          const allTownhalls = JSON.parse(store.allTownhalls);
          const allProjectsWithSubProjects = JSON.parse(store.projects).filter(project => project.subProjects != undefined);

          const findProjectInTownhalls = (projectId) => {
              return allTownhalls.find(town_hall => town_hall.gmao_id === projectId);
          };

          const findProjectInSubProjects = (projectId) => {
              for (const mainProject of allProjectsWithSubProjects) {
                  const subProject = mainProject.subProjects.find(sub => sub.id === projectId);
                  if (subProject) {
                      return mainProject;
                  }
              }
              return undefined;
          };

          const searchProjectRecursively = (projectId) => {
              const foundInTownhall = findProjectInTownhalls(projectId);
              if (foundInTownhall) {
                  return foundInTownhall;
              }
              const foundInSubProject = findProjectInSubProjects(projectId);
              if (foundInSubProject) {
                  return searchProjectRecursively(foundInSubProject.id);
              }
              return null;
          };

          const foundTownhall = searchProjectRecursively(project.id);
          if (foundTownhall) {
              store.currentTownhall = JSON.stringify(foundTownhall);
          } else {
            Swal.update({
              text: helper.loadLocaleMessages("t-unable-find-project-in-townhalls-search-for-admin")
            })
            return next('/login')
          }

          store.currentProject = res.data.data[0].project;
          let commandCenter = JSON.parse(res.data.data[0].command_center);
          commandCenter.in_maintenance = 1;
          store.currentCommandCenter = JSON.stringify(commandCenter);
          store.activeTab = 'management'
          if (window.location.href.includes('g-punto-a-punto')) {
            return false
          } else {
            return next('/g-punto-a-punto');
          }
      } else {
          store.performIssue = null;
          return next();
      }
    })
    .catch((error) => {
      if(error != undefined && error.response != undefined && error.response.status != undefined && error.response.status == 404) {
        Swal.fire({
          title: helper.loadLocaleMessages("t-error"),
          text: helper.loadLocaleMessages("t-cant-go-if-performing"),
        }).then(() => {
          return next();
        });
      } else if (
        error != undefined &&
        error.response != undefined &&
        error.response.status != undefined &&
        error.response.status == 401
      ) {
        helper.swalConfirm("t-warning", "t-lost-session", "warning", "js");
        useStore().resetStore();
        cacheStore().resetStore();
        localStorage.removeItem("performIssue");
        existsIssue = false
        useStore().performIssue = null;
        localStorage.clear()
        sessionStorage.clear()
        router.push({
          path: "/login",
        });
      }
    })
  } else {
    next();
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  let title = appConfig.title
  const hostname = window.location.hostname;
    if (hostname.includes('smartgest')) {
      title = 'Smartgest'
    }
  if (useStore().user != null) {
    let user = JSON.parse(useStore().user)
    if (user.name == 'Smartgest') {
      title = 'Smartgest'
    }
  }
  document.title = title;
  // If we reach this point, continue resolving the route.
  let windowSize = document.documentElement.clientWidth;
  if (
    windowSize <= 767 &&
    document.body.classList.contains("vertical-sidebar-enable")
  ) {
    document.body.classList.remove("vertical-sidebar-enable");
  }
  next();
});

export default router;
