import locale_es from "./es.json";
import locale_ca from "./ca.json";
import locale_en from "./en.json";


export const locales = {
    getLocale(name) {
        return this.locale_files[name]
    },
    locale_files: {locale_es, locale_ca, locale_en},
    languages: [
        {
          flag: require("@/assets/images/flags/spain.svg"),
          language: "es",
          title: "Español",
          iso_locale: 'es-ES'
        },
        {
          flag: require("@/assets/images/flags/gb.svg"),
          language: "en",
          title: "English",
          iso_locale: 'en-GB'
        },
        {
          flag: require("@/assets/images/flags/es-ct.svg"),
          language: "ca",
          title: "Català",
          iso_locale: 'ca-ES'
        },
    ]

}
