import { register } from "register-service-worker";

register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
      "La aplicación está siendo servida desde el caché por el Service Worker."
    );
  },
  registered() {
    console.log("Service Worker registrado.");
  },
  cached() {
    console.log("Contenido en caché para uso sin conexión.");
  },
  updatefound() {
    console.log("Nueva versión encontrada. Descargando...");
  },
  updated(registration) {
    console.log("Nueva versión lista. Recarga la página para actualizar.");
    if (
      confirm(
        "Se encontró una nueva versión de la aplicación. ¿Desea actualizarla ahora?"
      )
    ) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      location.reload();
    }
  },
  offline() {
    confirm(
      "La aplicación está fuera de línea. Por favor, revisa tu conexión a internet."
    );
    console.log(
      "La aplicación está fuera de línea. Por favor, revisa tu conexión a internet."
    );
  },
  error(error) {
    console.error("Error durante la instalación del Service Worker:", error);
  },
});
