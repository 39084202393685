import { defineStore } from "pinia";
import localforage from '../../localforage.config';

export const cacheStore = defineStore({
  id: "cacheStore",
  state: () => ({
    cache: {}
  }),
  actions: {
    async resetStore() {
      this.cache = {
        cache: {}
      };
      try {
        await localforage.removeItem('cache');
      } catch (error) {
        error
        // console.error('Error resetting cache in localforage', error);
      }
    },
    async setCache(data) {
      this.cache = data;
      try {
        await localforage.setItem('cache', data);
      } catch (error) {
        error
        // console.error('Error setting cache in localforage', error);
      }
    },
    async loadCache() {
      try {
        const data = await localforage.getItem('cache');
        // console.log(localforage)
        if (data !== null) {
          this.cache = data;
        }
      } catch (error) {
        error
        // console.error('Error loading cache from localforage', error);
      }
    }
  }
});
